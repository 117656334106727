// 基础路由
export const constantRouterMap = [{
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout/layout.vue'),
    redirect: {
        name: 'index'
    }, //重定向 到首页
    children: [
        // 首页
        {
            path: "/index",
            name: 'index',
            component: () => import('@/views/index.vue')
        },
        // 产品服务
        {
            path: "/smart",
            name: 'smart',
            component: () => import('@/views/smart.vue')

        },
        {
            path: "/system",
            name: 'system',
            component: () => import('@/views/system.vue')
        },
        {
            path: "/video",
            name: 'video',
            component: () => import('@/views/video.vue')
        },
        // 解决方案
        {
            path: "/solution",
            name: 'solution',
            component: () => import('@/views/solution.vue')
        },
        // 关于中壬
        {
            path: "/about",
            name: 'about',
            component: () => import('@/views/about.vue')
        },
        //    新闻详情
        {
            path: "/newdetail",
            name: 'newdetail',
            component: () => import('@/views/new-detail.vue')
        },

    ]
}, {
    //    软件下载
    path: "/download/:version(\\S+)?",
    name: 'download',
    component: () => import('@/views/downloadCenter.vue')
}]
