<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  window._html = window._html || []
  export default {
    name: 'App',
    created() {
      let hm = document.createElement("script");
      hm.id = "baidu_tj";
      hm.src = "https://hm.baidu.com/hm.js?6993dca6bfd694f1be8f58c54a0dd64d";
      let s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    }
  }
</script>

<style>

</style>
