import Vue from 'vue'
import loadingComponent from '@/components/loading.vue'
// 继承vue方法
const LoadingConstructor = Vue.extend(loadingComponent)

const instance = new LoadingConstructor({
  el: document.createElement('div')
})

instance.show = false // 默认隐藏
const loading = {
  show() { // 显示方法
    instance.show = true
    //添加至dom
    document.body.appendChild(instance.$el)
  },
  hide() { // 隐藏方法
    instance.show = false
  }
}

export default {
    // 插件
  install() {
    if (!Vue.$loading) {
      Vue.$loading = loading 
    }
    Vue.mixin({
      created() {
        this.$loading = Vue.$loading
      }
    })
  }
}