import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './static/css/bootstrap.css'
import './static/js/bootstrap.min.js'

// import 'video.js/dist/video-js.css'

import Axios from 'axios';

Vue.prototype.$axios = Axios

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)

import lodash from 'lodash'

Vue.prototype._ = lodash
import "./RouterListener"
import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

import loading from './utils/loading.js' // 引入loading
Vue.use(loading) // 全局使用loading

Vue.config.productionTip = false
// Vue.prototype.$loading = loading

import "@/static/css/global.css";

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
